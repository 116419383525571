<template lang="pug">
div(v-if="$store.state.app.accessSySafra")
  v-container(fluid)
    DataFilter(:filterProps="filters", :items="dataset", :paginate="false")
      template(v-slot:actions)
        v-tooltip(bottom)
          template(v-slot:activator="{ on }")
            v-btn.ml-2.mr-2(
              color="primary",
              @click="getData()",
              v-on="on",
              fab,
              small
            )
              v-icon(small) fa-solid fa-rotate-right
          span Atualizar

        v-menu(
          open-on-hover,
          bottom,
          offset-y,
          nudge-bottom="6",
          left,
          min-width="auto"
        )
          template(v-slot:activator="{ on, attrs }")
            v-btn(
              v-on="on",
              v-bind="attrs",
              fab,
              @click="expansionPanel = !expansionPanel",
              color="primary",
              small
            )
              v-icon(small) mdi mdi-help-box-outline
          v-card(outlined)
            v-card-text.pa-4
              .text-subtitle-2.pb-2 Regra padrão de validação dos backups:
              .d-flex.justify-between
                div(style="width: 150px")
                  .font-weight-bold Tipo de base
                  div {{ configValidacao ? configValidacao.tipobase : "" }}
                div(style="width: 150px")
                  .font-weight-bold Período
                  div {{ configValidacao ? configValidacao.periodo : "" }}

      template(v-slot:default="{ items }")
        v-data-table.monitorTable(
          dense,
          item-key="idweb",
          :headers="headers",
          :items="items",
          :footer-props="footer",
          :expanded.sync="expandedHistory",
          :single-expand="true",
          show-expand
        )
          template(v-slot:item.excecao="{ item }")
            div(v-if="item.excecao")
              .d-flex
                .font-weight-bold.pr-1.grey--text.text--darken-2 Tipo de base:
                div {{ item.excecao.tipobase }}
              .d-flex
                .font-weight-bold.pr-1.grey--text.text--darken-2 Período:
                div {{ item.excecao.periodo }}

          template(v-slot:item.statusserver="{ item }")
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-chip(
                  v-on="on",
                  v-bind="attrs",
                  :color="item.statusserver ? 'success' : 'error'",
                  small
                )
              span {{ item.statusserver ? "Conectado" : "Desconectado" }}

          template(v-slot:item.status="{ item }")
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-chip(
                  v-on="on",
                  v-bind="attrs",
                  :color="setStatus(item.status).color",
                  small
                )
              span {{ setStatus(item.status).x.substring(0, setStatus(item.status).x.length - 1) }}

          template(v-slot:item.validacaoAnterior="{ item }")
            v-row(v-if="item.validacao.length > 1", no-gutters)
              div(style="width: 140px; padding-top: 8px") {{ item.validacao[1].datahorainicio }}
              div(style="width: 120px")
                v-btn(
                  outlined,
                  width="24",
                  height="24",
                  icon,
                  :color="getColorStatus(item.validacao[1].statusValidacao)",
                  @click="getDetailsBackup(item.validacao[1].id)"
                )
                  v-icon mdi-information
                v-chip.ml-2(
                  :color="getColorStatus(item.validacao[1].statusValidacao)",
                  small,
                  @click="getDetailsBackup(item.validacao[1].id, item)"
                ) {{ firstUpperCase(item.validacao[1].statusValidacao) }}

          template(v-slot:item.statusValidacaoAnterior="{ item }")

          template(v-slot:item.validacaoAtual="{ item }")
            v-row(v-if="item.validacao.length > 0", no-gutters, align="center")
              div(style="width: 140px") {{ item.validacao[0].datahorainicio }}
              div(style="width: 120px")
                v-btn(
                  outlined,
                  width="24",
                  height="24",
                  icon,
                  :color="getColorStatus(item.validacao[0].statusValidacao)",
                  @click="getDetailsBackup(item.validacao[0].id, item)"
                )
                  v-icon mdi-information
                v-chip.ml-2(
                  :color="getColorStatus(item.validacao[0].statusValidacao)",
                  small,
                  @click="getDetailsBackup(item.validacao[0].id, item)"
                ) {{ firstUpperCase(item.validacao[0].statusValidacao) }}

          template(v-slot:item.statusValidacaoAtual="{ item }")
            v-row(v-if="item.validacao.length > 0", no-gutters, align="center")

          template(
            v-slot:item.data-table-expand="{ item, isExpanded, expand }"
          )
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-btn(
                  v-on="on",
                  v-bind="attrs",
                  :disabled="item.validacao.length === 0",
                  outlined,
                  width="30",
                  height="30",
                  icon,
                  color="primary",
                  small,
                  @click="expand(!isExpanded); getClientValidations(item.idcliente)"
                )
                  v-icon mdi-history
              span Histórico de backups

          template(
            v-slot:expanded-item="{ headers, item, expand, isExpanded }"
          )
            td(:colspan="headers.length")
              v-layout.ma-2(justify-center, wrap)
                v-row
                  v-col.d-flex.justify-end.mt-4.mb-2(cols="12")
                    div(style="width: 350px")
                      v-text-field.mr-2.mt-1(
                        v-model="search",
                        label="Buscar",
                        placeholder="Buscar",
                        hide-details,
                        outlined,
                        dense
                      )
                    div(style="width: 200px")
                      v-text-field.mr-2.mt-1(
                        v-model="periodoDates[0]",
                        label="Início do Período",
                        type="date",
                        outlined,
                        dense,
                        hide-details
                      )
                    div(style="width: 200px")
                      v-text-field.mr-2.mt-1(
                        v-model="periodoDates[1]",
                        label="Final do Período",
                        type="date",
                        outlined,
                        dense,
                        hide-details
                      )

                    v-divider.mx-2(vertical)

                    v-btn.mr-6(
                      fab,
                      small,
                      color="info",
                      title="Buscar",
                      @click="getClientValidations(item.idcliente)"
                    )
                      v-icon(small) fa-refresh

              v-divider.mb-0
              v-data-table.clientesLogsTable.ma-2(
                item-key=`${id}-${validacaoTipoCriacao}`,
                dense,
                disable-pagination,
                hide-default-footer,
                no-data-text="Nenhum registro encontrado",
                no-results-text="Nenhum registro encontrado",
                loading-text="Carregando...",
                :loading="isLoadingHistorico",
                :headers="headersLogs",
                :items="datasetValidacoesHistorico"
              )
                template(v-slot:item.validacaoStatus="{ item }")
                  v-btn(
                    outlined,
                    width="24",
                    height="24",
                    icon,
                    :color="getCombinedStatusColor(item.validacaoStatus)",
                    @click="getDetailsBackup(item.id)"
                  )
                    v-icon mdi-information
                  v-chip(
                    :color="getCombinedStatusColor(item.validacaoStatus)",
                    @click="getDetailsBackup(item.id)",
                    small
                  ) {{ getCombinedStatus(item.validacaoStatus) }}

                template(v-slot:item.validacaoDatahorainicio="{ item }")
                  div(
                    v-if="['FINALIZADO', 'EXECUTANDO'].includes(item.validacaoStatus)"
                  ) {{ item.validacaoDatahorainicio }}

                template(v-slot:item.validacaoDatahorafim="{ item }")
                  div(v-if="item.validacaoStatus == 'FINALIZADO'") {{ item.validacaoDatahorafim }}

                template(v-slot:item.validacaoTipoCriacao="{ item }")
                  div {{ item.validacaoTipoCriacao }}


  v-dialog(
    v-if="historyShowDetail",
    v-model="historyShowDetail",
    max-width="1000px",
    width="100%"
  )
    DadosCardWidget(
      :title="`Dados do backup - ${historyBackupDetail.cliente.id} - ${historyBackupDetail.cliente.nome}`",
      :dataset="historyBackupDetail",
      @close="historyShowDetail = false"
    )
</template>

<script>
import { mapMutations } from "vuex";
import csapi from "@/api/csapi";
import { socket, state } from "@/socket";
import DadosCardWidget from "./DadosCardWidget";

export default {
  components: { DadosCardWidget },
  data() {
    return {
      search: "",
      filters: { includeSearch: true, items: [] },
      headers: [
        {
          text: "Id",
          value: "idweb",
          width: "65"
        },
        {
          text: "Cliente",
          value: "nomecliente",
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0"
        },
        {
          text: "Status",
          value: "status",
          width: "100",
          align: "center",
          sortable: true,
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0"
        },
        {
          text: "Status CS Server",
          value: "statusserver",
          width: "120",
          align: "center",
          sortable: true,
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0"
        },
        {
          text: "Exceção",
          value: "excecao",
          width: "200",
          sortable: true,
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0"
        },
        {
          text: "Validação do Backup Anterior",
          value: "validacaoAnterior",
          width: "280",
          sortable: true,
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0"
        },
        {
          text: "Validação do Backup Atual",
          value: "validacaoAtual",
          width: "280",
          sortable: true,
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0"
        },
        {
          text: "Ações",
          value: "data-table-expand",
          width: "60",
          align: "center",
          sortable: false
        }
      ],
      headersLogs: [
        {
          text: "ID Backup",
          value: "id"
        },
        {
          text: "Início Backup",
          value: "datahorainicio"
        },
        {
          text: "Tipo de Validação",
          value: "validacaoTipoCriacao"
        },
        {
          text: "Status Validação",
          value: "validacaoStatus"
        },
        {
          text: "Início Validação",
          value: "validacaoDatahorainicio"
        },
        {
          text: "Fim Validação",
          value: "validacaoDatahorafim"
        }
      ],
      dataset: [],
      datasetValidacoesHistorico: [],
      isLoadingHistorico: false,
      expandedHistory: [],
      expansionPanel: false,
      historyShowDetail: false,
      historyBackupDetail: null,
      configValidacao: null,
      footer: {
        itemsPerPageOptions: [50, 100, 200, 300, 500, -1],
        showCurrentPage: true,
        showFirstLastPage: true,
        itemsPerPageAllText: "Todos",
        itemsPerPageText: "Itens por página"
      },
      periodoDates: [new Date(), new Date()],
      periodoDateMenu: false
    };
  },

  watch: {
    socketConnections() {},

    periodoDates: function(val) {
      if (val.length > 1) {
        if (new Date(val[0]) > new Date(val[1])) this.periodoDates = [val[1], val[0]];
      }
    }
  },

  methods: {
    ...mapMutations("app", ["setTitle", "isControl"]),
    ...mapMutations("loader", ["showLoader", "hideLoader"]),

    async getData() {
      this.showLoader();
      try {
        const l = "/sysafra/v1/sysbackup";

        const [clientes, backups, validacoes] = await Promise.all([
          csapi.get(`${l}/clientes`),
          csapi.get(`${l}/historico/clientes`),
          csapi.get(`${l}/item?&only-validations=true`)
        ]).then(rs => rs.map(r => r.data));

        validacoes.forEach(r => {
          r.statusValidacao = this.getStatusGeral(r.validacao.map(t => t.status));
        });

        backups.forEach(r => {
          // deleta backups e campos que não correspondem as validações
          delete r.backups;
          delete r.tamanhobucket;

          // busca as exceções do cliente
          const excecao = clientes.find(t => t.cliente.id === r.idcliente);
          r.excecao = excecao ? excecao.validacao : null;

          // busca as validações do cliente
          const vl = validacoes.filter(t => t.cliente.id === r.idcliente);
          if (vl) {
            r.validacao = vl;

            r.validacaoAnterior = vl.length > 1 ? vl[1].datahorainicio : null;
            r.validacaoAtual = vl.length > 0 ? vl[0].datahorainicio : null;

            r.validacao.forEach(t => {
              t.datahorainicio = new Date(t.datahorainicio).toLocaleString();
              t.datahorafim = new Date(t.datahorafim).toLocaleString();
            });
          } else {
            r.validacao = [];
          }

          // Controle de Status do CS Server
          r.statusserver = false;

          // Cliente está ativo ou inativo na ferramenta
          r.status = false;
          r.status = !r.config ? false : r.config.deletadoem !== null ? -1 : r.config.ativo;
          delete r.config;
        });

        //ordenar por status
        backups.sort((a, b) => b.status - a.status);

        this.dataset = backups;
        this.setFilters();
      } catch (error) {
        console.error(error);
      } finally {
        this.hideLoader();
      }
    },

    setStatus(s) {
      const statusMap = {
        "-1": { t: -1, x: "Removidos", color: "deep-orange lighten-3" },
        0: { t: 0, x: "Inativos", color: "grey lighten-1" },
        1: { t: 1, x: "Ativos", color: "success" }
      };
      return statusMap[s] || statusMap[0];
    },

    firstUpperCase(str) {
      if (str === null) return null;
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },

    getStatusGeral(statusArray) {
      if (statusArray === null) return null;
      if (statusArray.includes("ERRO")) return "ERRO";
      if (statusArray.includes("PENDENTE")) return "PENDENTE";
      if (statusArray.includes("EXECUTANDO")) return "EXECUTANDO";
      if (statusArray.includes("FINALIZADO")) return "FINALIZADO";
      return null;
    },

    getColorStatusBackup(status) {
      const statusColorMap = {
        ERRO: "error",
        CONCLUIDO: "success",
        EM_EXECUCAO: "info",
        TRANSFERINDO: "info"
      };
      return statusColorMap[status.toUpperCase()];
    },

    getColorStatus(status) {
      const statusColorMap = {
        ERRO: "error",
        FINALIZADO: "success",
        EXECUTANDO: "info",
        PENDENTE: "info"
      };
      return statusColorMap[status.toUpperCase()];
    },

    getCombinedStatus(combinedStatus) {
      const priorityOrder = ["ERRO", "PENDENTE", "EXECUTANDO", "FINALIZADO"];

      const statuses = combinedStatus.split("/");

      const highestPriorityStatus = statuses.reduce((highest, current) => {
        return priorityOrder.indexOf(current) < priorityOrder.indexOf(highest) ? current : highest;
      }, statuses[0]);

      return this.firstUpperCase(highestPriorityStatus);
    },

    getCombinedStatusColor(combinedStatus) {
      const statusText = this.getCombinedStatus(combinedStatus);
      return this.getColorStatus(statusText);
    },

    setFilters() {
      this.filters.items.length = 0;

      try {
        this.filters.items.push({
          text: "Cliente",
          type: "select",
          values: this.dataset.map(item => ({
            field: "idcliente",
            text: item.nomecliente,
            value: item.idcliente
          }))
        });
      } catch (error) {
        console.error(error);
      }

      try {
        this.filters.items.push({
          text: "Ativo",
          type: "select",
          values: this.dataset.map(item => ({
            field: "status",
            text: this.setStatus(item.status).x,
            value: this.setStatus(item.status).t
          }))
        });
      } catch (error) {
        console.error(error);
      }

      try {
        this.filters.items.push({
          text: "Status CS Server",
          type: "select",
          values: [
            { field: "statusserver", text: "Ativo", value: true },
            { field: "statusserver", text: "Inativo", value: false }
          ]
        });
      } catch (error) {
        console.error(error);
      }
    },

    formatDate(dateString) {
      return dateString
        .slice(0, 10)
        .split("-")
        .reverse()
        .join("/");
    },

    initDatesFilter() {
      const timezoneOffsetMs = new Date().getTimezoneOffset() * 60000;
      const oneDayMs = 86400000;
      const trintaDaysMs = oneDayMs * 30;

      const nowMs = Date.now();
      const startDateMs = nowMs - trintaDaysMs + timezoneOffsetMs;
      const initDate = new Date(startDateMs).toISOString().substr(0, 10);

      const endDateMs = nowMs + timezoneOffsetMs;
      const endDate = new Date(endDateMs).toISOString().substr(0, 10);

      this.periodoDates = [initDate, endDate];
    },

    async getConfigValidacao() {
      try {
        const res = await csapi.get("/sysafra/v1/sysbackup/config/validacao");

        if (res.status == 200) {
          this.configValidacao = res.data;
        }
      } catch (error) {
        this.$toast.error("Erro ao carregar configurações de validação");
      }
    },

    async fetchData(url, errorMessage) {
      this.showLoader("Carregando detalhes do backup...");
      try {
        const res = await csapi.get(url);
        if (res.status === 200) return res.data;
      } catch (error) {
        console.error(error);
        this.$toast.error(`${errorMessage}: ${error.message}`);
      } finally {
        this.hideLoader();
      }
    },

    async getDetailsBackup(hash, item) {
      const data = await this.fetchData(
        `/sysafra/v1/sysbackup/item/${hash}?include-details=true`,
        "Erro ao carregar dados do historico do storage"
      );

      if (data) {
        if (data.logs !== null) data.logs.reverse();
        data.validacao.forEach(item => {
          item.logs.reverse();
        });

        data.datahorainicio = new Date(data.datahorainicio).toLocaleString();
        data.datahorafim = new Date(data.datahorafim).toLocaleString();

        this.historyBackupDetail = data;
        this.historyShowDetail = true;
      }
    },

    async getClientValidations(idcliente) {
      // Verificar se data inicial é uma data valida
      if (isNaN(new Date(this.periodoDates[0]))) {
        this.$toast.error("Data inicial inválida");
        return;
      }

      // Verificar se data final é uma data valida
      if (isNaN(new Date(this.periodoDates[1]))) {
        this.$toast.error("Data final inválida");
        return;
      }

      // valida se a data inicial é maior que a data final
      if (new Date(this.periodoDates[0]) > new Date(this.periodoDates[1])) {
        this.$toast.error("Data inicial maior que data final");
        return;
      }

      this.datasetValidacoesHistorico = [];
      this.isLoadingHistorico = true;

      function formatarData(data) {
        return data.toLocaleString();
      }

      try {
        const baseUrl = "/sysafra/v1/sysbackup";
        const intervalo = this.periodoDates.concat(",").join();

        const res = await csapi.get(
          `${baseUrl}/item?client=${idcliente}&interval=${intervalo}&only-validations=true`
        );

        res.data.forEach(r => {
          r.datahorainicio = formatarData(new Date(r.datahorainicio));
          r.datahorafim = formatarData(new Date(r.datahorafim));

          r.statusvalidacao = this.getStatusGeral(r.validacao.map(t => t.status));

          const datasInicio = r.validacao.map(t => new Date(t.datahorainicio));
          const datasFim = r.validacao.map(t => new Date(t.datahorafim));

          // apos pegar todas as datas, retornar a mais antiga
          r.datahorainiciovalidacao = formatarData(new Date(Math.min(...datasInicio)));
          r.datahorafimvalidacao = formatarData(new Date(Math.max(...datasFim)));
        });

        function formatValidacao(dateString) {
          const date = new Date(dateString);
          const formattedDate = date.toLocaleDateString();
          const formattedTime = date.toLocaleTimeString();

          return `${formattedDate}, ${formattedTime}`;
        }

        const flattenedData = res.data.flatMap(r => {
          return r.validacao.map(validacao => {
            return {
              id: r.id,
              datahorainicio: r.datahorainicio,
              validacaoTipo: validacao.tipo,
              validacaoTipoCriacao: validacao.tipocriacao,
              validacaoStatus: validacao.status,
              validacaoDatahorainicio: formatValidacao(validacao.datahorainicio),
              validacaoDatahorafim: formatValidacao(validacao.datahorafim),
              clienteNome: r.cliente.nome
            };
          });
        });

        // agrupa por id e tipo de validacao
        const tipoCriacaoMap = new Map();

        flattenedData.forEach(item => {
          const key = `${item.id}-${item.validacaoTipoCriacao}`;

          if (!tipoCriacaoMap.has(key)) {
            tipoCriacaoMap.set(key, {
              item,
              hasLog: false,
              hasNormal: false,
              statusSet: new Set()
            });
          }

          const current = tipoCriacaoMap.get(key);

          if (item.validacaoTipo === "LOG") {
            current.hasLog = true;
          }

          if (item.validacaoTipo === "NORMAL") {
            current.hasNormal = true;
          }

          current.statusSet.add(item.validacaoStatus);
        });

        // agrupa conforme o tipo e o status
        const uniqueFlattenedData = Array.from(tipoCriacaoMap.values()).map(
          ({ item, hasLog, hasNormal, statusSet }) => {
            if (hasLog && hasNormal) {
              item.validacaoTipo = "LOG/NORMAL";
            } else if (hasLog) {
              item.validacaoTipo = "LOG";
            } else if (hasNormal) {
              item.validacaoTipo = "NORMAL";
            }

            item.validacaoStatus = Array.from(statusSet).join("/");

            return item;
          }
        );

        // dados flat para o datatable de historico
        this.datasetValidacoesHistorico = uniqueFlattenedData;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoadingHistorico = false;
      }
    }
  },

  created() {
    if (!this.$store.state.app.accessSySafra) {
      this.$store.state.app.redirectToControl();
    }
  },

  computed: {
    periodoDatesText() {
      const hasStartDate = this.periodoDates.length > 0;
      const hasEndDate = this.periodoDates.length > 1;

      const startDate = hasStartDate ? this.formatDate(this.periodoDates[0]) : "";
      const endDate = hasEndDate ? this.formatDate(this.periodoDates[1]) : "";

      if (!hasStartDate) return " ";
      return hasEndDate ? `${startDate} ~ ${endDate}` : startDate;
    },

    socketConnections() {
      this.dataset.forEach(item => {
        const i = state.socketConnections.findIndex(row => item.hash === row.data.token.hash);
        item.statusserver = i !== -1;
      });

      return state.socketConnections;
    },

    getTipocriacao(item) {
      if (item.validacao.length > 0) {
        const found = item.validacao.find(v => v.tipocriacao);
        return found ? found.tipocriacao : "N/A";
      }
      return "N/A";
    }
  },

  mounted() {
    if (!this.$store.state.app.accessSySafra) return;

    this.isControl(false);
    this.setTitle("SySBackup - Validações");

    this.getData();
    this.getConfigValidacao();
    this.initDatesFilter();

    const hashToItemMap = new Map(this.dataset.map(item => [item.hash, item]));

    socket.on("sysafra:sysbackup:historico:monitor", row => {
      if (typeof row.data !== "object" || !("token" in row.data)) return;

      const item = hashToItemMap.get(row.data.token.hash);
      if (item) {
        this.$set(item, "statusserver", true);
      }
    });
  },

  beforeDestroy() {
    socket.off("sysafra:sysbackup:historico:monitor");
  }
};
</script>

<style>
.monitorTable .v-data-table__wrapper {
  height: calc(100vh - 220px);
  overflow-y: auto;
}

.clientesLogsTable .v-data-table__wrapper {
  height: auto;
  overflow-y: auto;
}
</style>
