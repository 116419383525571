<template lang="pug">
div
  v-card
    v-card-title
      div(style="width: 900px") {{ title }}
      v-btn.ma-0.pa-0(@click="closePage", icon)
        v-icon mdi-close
    v-card-text
      v-container(fluid)
        v-row(no-gutters)
          v-col.ma-2.ml-0.pl-0.d-flex(cols="12", sm="12", md="12")
            div(style="width: 155px")
              h3 Backup
          v-col(cols="12", sm="2", md="2")
            b ID
          v-col(cols="12", sm="10", md="10")
            span {{ dataset.id }}
          v-col(cols="12", sm="2", md="2")
            b Data/Hora Início
          v-col(cols="12", sm="10", md="10")
            span {{ dataset.datahorainicio }}
          v-col(cols="12", sm="2", md="2")
            b Data/Hora Fim
          v-col(cols="12", sm="10", md="10")
            span {{ dataset.datahorafim }}
          v-col(cols="12", sm="2", md="2")
            b Status
          v-col(cols="12", sm="7", md="7")
            v-chip(:color="getColorStatus(dataset.status)", small) {{ dataset.status }}
          v-col.pr-4(cols="12", sm="3", md="3", justify="end", align="end")
            v-btn(
              small,
              @click="dialogEncaminharBackup = true",
              outlined,
              color="primary"
            ) Solicitar Backup
            v-btn(
              small,
              @click="dialogValidarBackup = true",
              outlined,
              color="primary"
            ) Validar Backup
          v-col.ma-2.ml-0.pl-0(cols="12", sm="12", md="12")
            v-divider

        v-row(no-gutters)
          v-tabs(v-model="tabs")
            v-tab Arquivos
            v-tab Backup - Logs
            v-tab(v-for="valid in dataset.validacao", :key="valid.id") Validação - Base {{ valid.tipo }} {{ getColorStatusValidIcon(valid.status) }}

          v-tabs-items(v-model="tabs")
            v-tab-item(style="width: -webkit-fill-available")
              v-col(cols="12", sm="12", md="12")
                v-data-table(
                  dense,
                  disable-pagination,
                  hide-default-footer,
                  :single-expand="true",
                  :headers="dataHeadersMd5",
                  :items="dataset.arquivos"
                )
                  template(v-slot:item.tamanho="{ item }")
                    span {{ bytesToMegabytes(item.tamanho) }}

            v-tab-item(style="width: -webkit-fill-available")
              v-col(cols="12", sm="12", md="12")
                v-data-table(
                  dense,
                  disable-pagination,
                  hide-default-footer,
                  show-expand,
                  :headers="dataHeadersBackupLogs",
                  :items="dataset.logs"
                )
                  template(v-slot:item.datahora="{ item }")
                    span {{ new Date(item.datahora).toLocaleString() }}

                  template(
                    v-slot:item.data-table-expand="{ item, isExpanded, expand }"
                  )
                    v-icon(
                      v-if="item.detalhe !== null",
                      @click="expand(!isExpanded)"
                    ) mdi-chevron-down

                  template(v-slot:expanded-item="{ headers, item }")
                    td(:colspan="headers.length") {{ item.detalhe }}

            v-tab-item(v-for="valid in dataset.validacao", :key="valid.id")
              v-row.pl-7.pt-4(no-gutters)
                v-col(cols="12", sm="2", md="2")
                  b ID
                v-col(cols="12", sm="10", md="10")
                  span {{ valid.id }}

                v-col(cols="12", sm="2", md="2")
                  b Data/Hora Início
                v-col(cols="12", sm="10", md="10")
                  span {{ new Date(valid.datahorainicio).getTime() > 1000000 ? new Date(valid.datahorainicio).toLocaleString() : "" }}
                v-col(cols="12", sm="2", md="2")
                  b Data/Hora Fim
                v-col(cols="12", sm="10", md="10")
                  span {{ new Date(valid.datahorafim).getTime() > 1000000 ? new Date(valid.datahorafim).toLocaleString() : "" }}
                v-col(cols="12", sm="2", md="2")
                  b Tipo Validação
                v-col(cols="12", sm="10", md="10")
                  span {{ valid.tipocriacao }}
                v-col(cols="12", sm="2", md="2")
                  b Status
                v-col(cols="12", sm="10", md="10")
                  v-chip(:color="getColorStatusValid(valid.status)", small) {{ valid.status }}
                v-col.mb-2.mt-2(cols="12", sm="2", md="2")
                  b Arquivos
                v-col.mb-2.mt-2(cols="12", sm="10", md="10")
                  span {{ valid.arquivos.join(", ") }}

              v-col(cols="12", sm="12", md="12")
                v-data-table(
                  dense,
                  disable-pagination,
                  hide-default-footer,
                  show-expand,
                  :headers="dataHeadersBackupsValidacoes",
                  :items="valid.logs"
                )
                  template(v-slot:item.datahora="{ item }")
                    div {{ new Date(item.datahora).toLocaleString() }}

                  template(
                    v-slot:item.data-table-expand="{ item, isExpanded, expand }"
                  )
                    v-icon(
                      v-if="item.detalhe !== null",
                      @click="expand(!isExpanded)"
                    ) mdi-chevron-down

                  template(v-slot:expanded-item="{ headers, item }")
                    td(:colspan="headers.length") {{ item.detalhe }}

  v-dialog(
    v-model="dialogEncaminharBackup",
    v-if="dialogEncaminharBackup",
    max-width="600px",
    width="100%"
  )
    v-card
      v-card-title
        span Solicitação de Backup
        v-spacer
        v-btn(@click="dialogEncaminharBackup = false", icon)
          v-icon mdi-close
      v-card-text
        .ma-1 Nota: Ao confirmar o backup será solicitado. Quando estiver disponível será enviado e-mail com link para download.
        v-card.pa-3.mt-4(outlined, elevation="0")
          v-form(ref="form")
            v-col(cols="12", sm="12", md="12")
              v-text-field(
                v-model="motivoSolicitacaoBackup.email",
                label="E-mail para envio do link",
                dense,
                :disabled="dialogEncaminharBackupSucesso",
                :rules="[rules.required, rules.email]"
              )

            v-col(cols="12", sm="12", md="12")
              v-text-field(
                v-model="motivoSolicitacaoBackup.motivo",
                label="Motivo da solicitação",
                dense,
                :disabled="dialogEncaminharBackupSucesso",
                :rules="[rules.required, rules.min10char]"
              )
      v-card-actions(justify="right")
        v-col.text-right.pt-0.pr-0(cols="12", sm="12", md="12")
          v-btn(
            v-if="!dialogEncaminharBackupSucesso",
            color="success",
            @click="setSolicitarBackup",
            outlined
          ) Confirmar
          v-btn(
            v-else,
            color="primary",
            to="/sysafra/sysbackup/solicitacoes",
            outlined
          ) Acompanhar Solicitação

  //- dialog para confirmar se o usuario deseja prosseguir com envio da recuperação
  v-dialog(
    v-model="dialogEncaminharBackupEmAndamento",
    max-width="600px",
    width="100%"
  )
    v-card
      v-card-title
        span Atenção
      v-card-text
        h4.ma-1.grey--text.text--darken-3 Existe uma solicitação deste backup em ativo para este cliente. Deseja prosseguir com a solicitação?
      v-card-actions.pa-3(justify="right")
        v-spacer
        v-btn(
          color="error",
          outlined,
          @click="dialogEncaminharBackupEmAndamento = false; dialogEncaminharBackup = false"
        ) Cancelar
        v-btn(color="success", outlined, @click="setSolicitarBackup") Confirmar

  //- dialog para confirmar se o usuario deseja prosseguir com envio da validação de backup
  v-dialog(v-model="dialogValidarBackup", max-width="600px", width="100%")
    v-card
      v-card-title
        span Validação de Backup
        v-spacer
        v-btn(@click="dialogValidarBackup = false", icon)
          v-icon mdi-close
      v-card-text
        .ma-1 Nota: Ao confirmar, a validação será solicitada para o serviço de validação. Você pode acompanhar o seu andamento pelo monitor de validações.
      v-card-actions(justify="right")
        v-col.text-right.pt-0.pr-0(cols="12", sm="12", md="12")
          v-btn.mr-2(
            color="primary",
            outlined,
            @click="dialogValidarBackup = false"
          ) Cancelar
          v-btn(color="success", @click="setSolicitarValidacao", outlined) Confirmar
</template>

<script>
import csapi from "@/api/csapi";
import { mapMutations } from "vuex";

export default {
  name: "DadosCardWidget",
  props: {
    title: {
      type: String,
      default: ""
    },
    dataset: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      tabs: null,
      dataHeadersMd5: [
        {
          text: "Nome",
          value: "nome",
          width: "500",
          sortable: false
        },
        {
          text: "Tamanho",
          value: "tamanho",
          sortable: false
        },
        {
          text: "MD5",
          value: "md5",
          sortable: false
        }
      ],
      dataHeadersBackupLogs: [
        {
          text: "Data/Hora Início",
          value: "datahora",
          width: "200",
          sortable: false
        },
        {
          text: "Tipo",
          value: "tipobackup",
          width: "150",
          sortable: false
        },
        {
          text: "Mensagem",
          value: "mensagem.mensagem",
          width: "480",
          sortable: false
        },
        {
          text: "#",
          value: "data-table-expand",
          sortable: false
        }
      ],
      dataHeadersBackupsValidacoes: [
        {
          text: "Início/Fim",
          value: "datahora",
          width: "180",
          sortable: false
        },
        {
          text: "Mensagem",
          value: "mensagem.mensagem",
          sortable: false
        },
        {
          text: "#",
          value: "data-table-expand",
          sortable: false
        }
      ],
      dialogEncaminharBackup: false,
      dialogEncaminharBackupSucesso: false,
      dialogEncaminharBackupEmAndamento: false,
      dialogValidarBackup: false,
      motivoSolicitacaoBackup: {
        email: "",
        motivo: ""
      },
      rules: {
        required: value => !!value || "Campo obrigatório.",
        email: value => {
          const pattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
          return pattern.test(value) || "E-mail inválido.";
        },
        min10char: v => v.length >= 10 || "Mínimo de 10 caracteres."
      }
    };
  },
  methods: {
    ...mapMutations("loader", ["showLoader", "hideLoader"]),
    formatDate(date) {
      return new Date(date).toLocaleString();
    },

    getColorStatus(status) {
      if (status === "ERRO") return "error";
      if (status === "EM_EXECUCAO") return "warning";
      if (status === "CONCLUIDO") return "success";
      return "grey";
    },

    getColorStatusValid(status) {
      const statusColorMap = {
        ERRO: "error",
        FINALIZADO: "success",
        EXECUTANDO: "info",
        PENDENTE: "info"
      };
      return statusColorMap[status];
    },

    getColorStatusValidIcon(status) {
      const statusColorMap = {
        ERRO: "❌",
        FINALIZADO: "✔️",
        EXECUTANDO: "🕣",
        PENDENTE: "🕣"
      };
      return statusColorMap[status];
    },

    bytesToMegabytes(bytes) {
      if (bytes === 0) return "0 Bytes";

      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = Math.floor(Math.log(bytes) / Math.log(1024));

      const formattedSize = (bytes / Math.pow(1024, i)).toFixed(2);

      return `${formattedSize} ${sizes[i]}`;
    },

    closePage() {
      this.$emit("close");
    },

    async setSolicitarValidacao() {
      this.showLoader("Solicitando validação...");
      try {
        const res = await csapi.post(`/sysafra/v1/sysbackup/item/${this.dataset.id}/validar`);

        if (res.status === 200) {
          this.$toast.success("Validação solicitado com sucesso!");
        }
      } catch (error) {
        this.$toast.error(
          error.response !== null
            ? `Não foi possivel solicitar a validação! ${error.response.data.message}`
            : `Não foi possivel solicitar a validação! ${error.message}`
        );
      } finally {
        this.dialogValidarBackup = false;
        this.hideLoader();
      }
    },

    async setSolicitarBackup() {
      const valid = await this.$refs.form.validate();
      if (!valid) return;

      if (!this.dialogEncaminharBackupEmAndamento) {
        const solicitacaoAberto = await csapi.get(
          `/sysafra/v1/sysbackup/recuperacao?idbackup=${this.dataset.id}`
        );

        if (solicitacaoAberto.data.length > 0 && !this.dialogEncaminharBackupEmAndamento) {
          if (
            new Date(solicitacaoAberto.data[0].datahorainicio).getTime() >
            new Date().getTime() - 86400000
          ) {
            this.dialogEncaminharBackupEmAndamento = true;
            return;
          }
        }
      } else {
        this.dialogEncaminharBackupEmAndamento = false;
      }

      this.showLoader("Solicitando backup...");
      try {
        const { login, name } = JSON.parse(JSON.stringify(this.$store.state.auth.user));

        const res = await csapi.post(`/sysafra/v1/sysbackup/item/${this.dataset.id}/recuperar`, {
          usuario: `${login}:${name}`,
          motivo: this.motivoSolicitacaoBackup.motivo,
          emails: [this.motivoSolicitacaoBackup.email]
        });

        if (res.status === 200) {
          this.$toast.success("Backup solicitado com sucesso!");
          this.dialogEncaminharBackupSucesso = true;
        }
      } catch (error) {
        if (error.response !== null) {
          this.$toast.error(`Não foi possivel solicitar o backup! ${error.response.data.message}`);
        } else {
          this.$toast.error(`Não foi possivel solicitar o backup! ${error.message}`);
        }
        this.hideLoader();
      } finally {
        this.dialogEncaminharBackupSucesso = true;
      }

      this.hideLoader();
    }
  },
  watch: {
    dialogEncaminharBackup(status) {
      if (status) return;
      this.dialogEncaminharBackupSucesso = false;
      this.motivoSolicitacaoBackup = {
        email: "",
        motivo: ""
      };
    }
  }
};
</script>
