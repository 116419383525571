var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.app.accessSySafra)?_c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('DataFilter',{attrs:{"filterProps":_vm.filters,"items":_vm.dataset,"paginate":false},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2 mr-2",attrs:{"color":"primary","fab":"","small":""},on:{"click":function($event){return _vm.getData()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-solid fa-rotate-right")])],1)]}}],null,false,1337800235)},[_c('span',[_vm._v("Atualizar")])]),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":"","nudge-bottom":"6","left":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","color":"primary","small":""},on:{"click":function($event){_vm.expansionPanel = !_vm.expansionPanel}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi mdi-help-box-outline")])],1)]}}],null,false,1237947686)},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"pa-4"},[_c('div',{staticClass:"text-subtitle-2 pb-2"},[_vm._v("Regra padrão de validação dos backups:")]),_c('div',{staticClass:"d-flex justify-between"},[_c('div',{staticStyle:{"width":"150px"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Tipo de base")]),_c('div',[_vm._v(_vm._s(_vm.configValidacao ? _vm.configValidacao.tipobase : ""))])]),_c('div',{staticStyle:{"width":"150px"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Período")]),_c('div',[_vm._v(_vm._s(_vm.configValidacao ? _vm.configValidacao.periodo : ""))])])])])],1)],1)]},proxy:true},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('v-data-table',{staticClass:"monitorTable",attrs:{"dense":"","item-key":"idweb","headers":_vm.headers,"items":items,"footer-props":_vm.footer,"expanded":_vm.expandedHistory,"single-expand":true,"show-expand":""},on:{"update:expanded":function($event){_vm.expandedHistory=$event}},scopedSlots:_vm._u([{key:"item.excecao",fn:function(ref){
var item = ref.item;
return [(item.excecao)?_c('div',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"font-weight-bold pr-1 grey--text text--darken-2"},[_vm._v("Tipo de base:")]),_c('div',[_vm._v(_vm._s(item.excecao.tipobase))])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"font-weight-bold pr-1 grey--text text--darken-2"},[_vm._v("Período:")]),_c('div',[_vm._v(_vm._s(item.excecao.periodo))])])]):_vm._e()]}},{key:"item.statusserver",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":item.statusserver ? 'success' : 'error',"small":""}},'v-chip',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.statusserver ? "Conectado" : "Desconectado"))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":_vm.setStatus(item.status).color,"small":""}},'v-chip',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.setStatus(item.status).x.substring(0, _vm.setStatus(item.status).x.length - 1)))])])]}},{key:"item.validacaoAnterior",fn:function(ref){
var item = ref.item;
return [(item.validacao.length > 1)?_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticStyle:{"width":"140px","padding-top":"8px"}},[_vm._v(_vm._s(item.validacao[1].datahorainicio))]),_c('div',{staticStyle:{"width":"120px"}},[_c('v-btn',{attrs:{"outlined":"","width":"24","height":"24","icon":"","color":_vm.getColorStatus(item.validacao[1].statusValidacao)},on:{"click":function($event){return _vm.getDetailsBackup(item.validacao[1].id)}}},[_c('v-icon',[_vm._v("mdi-information")])],1),_c('v-chip',{staticClass:"ml-2",attrs:{"color":_vm.getColorStatus(item.validacao[1].statusValidacao),"small":""},on:{"click":function($event){return _vm.getDetailsBackup(item.validacao[1].id, item)}}},[_vm._v(_vm._s(_vm.firstUpperCase(item.validacao[1].statusValidacao)))])],1)]):_vm._e()]}},{key:"item.statusValidacaoAnterior",fn:function(ref){
var item = ref.item;
return undefined}},{key:"item.validacaoAtual",fn:function(ref){
var item = ref.item;
return [(item.validacao.length > 0)?_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('div',{staticStyle:{"width":"140px"}},[_vm._v(_vm._s(item.validacao[0].datahorainicio))]),_c('div',{staticStyle:{"width":"120px"}},[_c('v-btn',{attrs:{"outlined":"","width":"24","height":"24","icon":"","color":_vm.getColorStatus(item.validacao[0].statusValidacao)},on:{"click":function($event){return _vm.getDetailsBackup(item.validacao[0].id, item)}}},[_c('v-icon',[_vm._v("mdi-information")])],1),_c('v-chip',{staticClass:"ml-2",attrs:{"color":_vm.getColorStatus(item.validacao[0].statusValidacao),"small":""},on:{"click":function($event){return _vm.getDetailsBackup(item.validacao[0].id, item)}}},[_vm._v(_vm._s(_vm.firstUpperCase(item.validacao[0].statusValidacao)))])],1)]):_vm._e()]}},{key:"item.statusValidacaoAtual",fn:function(ref){
var item = ref.item;
return [(item.validacao.length > 0)?_c('v-row',{attrs:{"no-gutters":"","align":"center"}}):_vm._e()]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":item.validacao.length === 0,"outlined":"","width":"30","height":"30","icon":"","color":"primary","small":""},on:{"click":function($event){expand(!isExpanded); _vm.getClientValidations(item.idcliente)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-history")])],1)]}}],null,true)},[_c('span',[_vm._v("Histórico de backups")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-layout',{staticClass:"ma-2",attrs:{"justify-center":"","wrap":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end mt-4 mb-2",attrs:{"cols":"12"}},[_c('div',{staticStyle:{"width":"350px"}},[_c('v-text-field',{staticClass:"mr-2 mt-1",attrs:{"label":"Buscar","placeholder":"Buscar","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticStyle:{"width":"200px"}},[_c('v-text-field',{staticClass:"mr-2 mt-1",attrs:{"label":"Início do Período","type":"date","outlined":"","dense":"","hide-details":""},model:{value:(_vm.periodoDates[0]),callback:function ($$v) {_vm.$set(_vm.periodoDates, 0, $$v)},expression:"periodoDates[0]"}})],1),_c('div',{staticStyle:{"width":"200px"}},[_c('v-text-field',{staticClass:"mr-2 mt-1",attrs:{"label":"Final do Período","type":"date","outlined":"","dense":"","hide-details":""},model:{value:(_vm.periodoDates[1]),callback:function ($$v) {_vm.$set(_vm.periodoDates, 1, $$v)},expression:"periodoDates[1]"}})],1),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_c('v-btn',{staticClass:"mr-6",attrs:{"fab":"","small":"","color":"info","title":"Buscar"},on:{"click":function($event){return _vm.getClientValidations(item.idcliente)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-refresh")])],1)],1)],1)],1),_c('v-divider',{staticClass:"mb-0"}),_c('v-data-table',{staticClass:"clientesLogsTable ma-2",attrs:{"item-key":"undefined-undefined","dense":"","disable-pagination":"","hide-default-footer":"","no-data-text":"Nenhum registro encontrado","no-results-text":"Nenhum registro encontrado","loading-text":"Carregando...","loading":_vm.isLoadingHistorico,"headers":_vm.headersLogs,"items":_vm.datasetValidacoesHistorico},scopedSlots:_vm._u([{key:"item.validacaoStatus",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","width":"24","height":"24","icon":"","color":_vm.getCombinedStatusColor(item.validacaoStatus)},on:{"click":function($event){return _vm.getDetailsBackup(item.id)}}},[_c('v-icon',[_vm._v("mdi-information")])],1),_c('v-chip',{attrs:{"color":_vm.getCombinedStatusColor(item.validacaoStatus),"small":""},on:{"click":function($event){return _vm.getDetailsBackup(item.id)}}},[_vm._v(_vm._s(_vm.getCombinedStatus(item.validacaoStatus)))])]}},{key:"item.validacaoDatahorainicio",fn:function(ref){
var item = ref.item;
return [(['FINALIZADO', 'EXECUTANDO'].includes(item.validacaoStatus))?_c('div',[_vm._v(_vm._s(item.validacaoDatahorainicio))]):_vm._e()]}},{key:"item.validacaoDatahorafim",fn:function(ref){
var item = ref.item;
return [(item.validacaoStatus == 'FINALIZADO')?_c('div',[_vm._v(_vm._s(item.validacaoDatahorafim))]):_vm._e()]}},{key:"item.validacaoTipoCriacao",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.validacaoTipoCriacao))])]}}],null,true)})],1)]}}],null,true)})]}}],null,false,4106634506)})],1),(_vm.historyShowDetail)?_c('v-dialog',{attrs:{"max-width":"1000px","width":"100%"},model:{value:(_vm.historyShowDetail),callback:function ($$v) {_vm.historyShowDetail=$$v},expression:"historyShowDetail"}},[_c('DadosCardWidget',{attrs:{"title":("Dados do backup - " + (_vm.historyBackupDetail.cliente.id) + " - " + (_vm.historyBackupDetail.cliente.nome)),"dataset":_vm.historyBackupDetail},on:{"close":function($event){_vm.historyShowDetail = false}}})],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }